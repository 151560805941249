<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='toc m-auto'>
        <skeleton-loader-component :loading='!esim'>
          <template v-if='esim'>
            <div class='flex mt-8 text-gray-700'>
              <router-link class='flex'
                :to='{ name: "esims" }'>
                <i class='icon'>arrow_back</i>
              </router-link>
              <div class='ml-16 text-lg'>{{ esim.order.item.data.name }}</div>
            </div>
            <card-element class='mt-24'>
              <div class='flex'>
                <plan-image-component class='rem overflow-hidden rounded'
                  :plan='esim.order.item.data'/>
                <div class='ml-24'>
                  <div class='text-xl'>{{ esim.order.item.data.name }}</div>
                  <div class='flex mt-16'>
                    <i class='icon text-gray-700'>signal_cellular_alt</i>
                    <div class='ml-16'>{{ esim.order.item.data.networkType }}</div>
                  </div>
                </div>
              </div>
              <div class='mt-24'>
                <div class='flex justify-between'>
                  <div class='flex'>
                    <i class='icon text-gray-700'>sim_card</i>
                    <div class='ml-16'>{{ $t('common.serial-number') }}</div>
                  </div>
                  <div class='ml-24 truncate'>{{ esim.serialNumber }}</div>
                </div>
                <div class='flex justify-between mt-24'>
                  <div class='flex'>
                    <i class='icon text-gray-700'>call</i>
                    <div class='ml-16'>{{ $t('common.phone-number') }}</div>
                  </div>
                  <div class='ml-24 truncate'>{{ esim.phoneNumber }}</div>
                </div>
                <div class='flex justify-between mt-24'>
                  <div class='flex'>
                    <i class='icon text-gray-700'>receipt</i>
                    <div class='ml-16 whitespace-no-wrap'>{{ $t('common.order-number') }}</div>
                  </div>
                  <router-link class='ml-24 truncate border-gray-300 border-dashed border-b cursor-pointer'
                    :to='{ name: "order", params: { id: esim.order.id } }'>
                    {{ esim.order.orderNumber }}
                  </router-link>
                </div>
                <div class='flex justify-between mt-24'>
                  <div class='flex'>
                    <i class='icon text-gray-700'>event</i>
                    <div class='ml-16'>{{ $t('common.purchase-date') }}</div>
                  </div>
                  <div>{{ formatDate(esim.order.createdDate) }}</div>
                </div>
              </div>
            </card-element>
            <div class='mt-24'>
              <router-link class='flex items-center justify-center p-16 text-white bg-primary hover:bg-primary-dark rounded transition duration-200 cursor-pointer'
                :to='{ name: "esim-qr-code", params: { id: esim.id } }'>
                <img class='w-48'
                  src='images/b848.png'>
                <div class='ml-24'>{{ $t('pages.esim.acc7acdb') }}</div>
              </router-link>
              <router-link class='flex items-center justify-center p-16 mt-8 text-white bg-secondary hover:bg-secondary-dark rounded transition duration-200 cursor-pointer'
                :to='{ name: "esim-manual", params: { id: esim.id } }'>
                <img class='w-48'
                  src='images/ee84.png'>
                <div class='ml-24'>{{ $t('pages.esim.02a4f903') }}</div>
              </router-link>
            </div>
            <card-element class='mt-24'>
              <template v-if='esim.plan.status === "ready"'>
                <div class='flex items-center justify-between pb-16 border-gray-300 border-b'>
                  <div>{{ $t('common.data-usage') }}</div>
                  <badge-element class='whitespace-no-wrap bg-orange'>{{ $t('common.not-activated') }}</badge-element>
                </div>
                <div class='flex justify-between mt-24'>
                  <div>{{ $t('common.data') }}</div>
                  <div v-if='esim.order.item.data.data.value > 0'>{{ esim.order.item.data.data.value }} {{ esim.order.item.data.data.unit }}</div>
                  <div v-else>{{ $t('common.unlimited') }}</div>
                </div>
                <div class='flex justify-between mt-24'>
                  <div class='whitespace-no-wrap'>{{ $t('common.install-before') }}</div>
                  <div class='ml-24'>{{ formatDate(esim.expirationDate, 'gmt') }}</div>
                </div>
              </template>
              <template v-if='esim.plan.status === "active"'>
                <div class='flex items-center justify-between pb-16 border-gray-300 border-b'>
                  <div>{{ $t('common.data-usage') }}</div>
                  <badge-element class='whitespace-no-wrap'>{{ $t('common.active') }}</badge-element>
                </div>
                <div class='flex justify-between mt-24'>
                  <div>{{ $t('common.remaining-data') }}</div>
                  <div v-if='esim.order.item.data.data.value > 0'>{{ esim.plan.remainingData.value }} / {{ esim.order.item.data.data.value }} {{ esim.order.item.data.data.unit }}</div>
                  <div v-else>{{ $t('common.unlimited') }}</div>
                </div>
                <progress-element class='mt-16'
                  :value='100 * esim.plan.remainingData.value / esim.order.item.data.data.value'/>
                <div class='flex justify-end mt-24'>
                  <div class='text-gray-500'>{{ formatDate(esim.plan.expirationDate, 'gmt') }}</div>
                  <div class='ml-16'>{{ formatDistanceToNow(esim.plan.expirationDate) }} {{ $tc('common.day-left', formatDistanceToNow(esim.plan.expirationDate)) }}</div>
                </div>
              </template>
              <template v-if='esim.plan.status === "expired"'>
                <div class='flex items-center justify-between pb-16 border-gray-300 border-b'>
                  <div>{{ $t('common.data-usage') }}</div>
                  <badge-element class='whitespace-no-wrap bg-gray-500'>{{ $t('common.expired') }}</badge-element>
                </div>
                <div class='flex justify-between mt-24'>
                  <div>{{ $t('common.expired-on') }}</div>
                  <div>{{ formatDate(esim.plan.expirationDate) }}</div>
                </div>
              </template>
            </card-element>
            <header-element class='mt-24'>{{ $t('pages.esim.050063dd') }}</header-element>
            <card-element class='mt-24'>
              <div class='jus flex items-center'
                v-for='country in esim.order.item.data.countries'
                :key='country.code'>
                <img class='w-24'
                  :src='`flags/countries/${country.code.toLowerCase()}.svg`'>
                <div class='ml-16'>{{ country.name }}</div>
              </div>
            </card-element>
            <template v-if='esim.order.item.data.additionalInfo.length'>
              <header-element class='mt-24'>{{ $t('pages.esim.9481497f') }}</header-element>
              <card-element class='mt-24'>
                <div class='jus flex'
                  v-for='(info, index) in esim.order.item.data.additionalInfo'
                  :key='index'>
                  <div class='text-gray-700'>-</div>
                  <div class='ml-16'>{{ info }}</div>
                </div>
              </card-element>
            </template>
          </template>
        </skeleton-loader-component>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import PlanImage from '@/components/plan-image'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'
  import dateMixin from '@/mixins/date'
  import store from '@/store'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'plan-image-component': PlanImage,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation
    },

    mixins: [dateMixin],

    props: {
      id: String
    },

    data: () => ({
      esim: null
    }),

    mounted () {
      this.fetchEsim()
    },

    methods: {
      async fetchEsim () {
        let id = this.id
        this.esim = await this.$store.dispatch('me/getEsim', { id })
      }
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch('auth/getUser')
      if (!user) {
        next({ name: 'login' })
        return
      }
      next()
    }
  }
</script>

<style lang='scss' scoped>
  .toc {
    max-width: 480px;
  }

  .rem {
    height: 90px;
  }

  .jus {
    &:not(:first-child) {
      @apply mt-16;
    }
  }
</style>
